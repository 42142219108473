<template>
  <!--
    // table 表格数据内容
    //tableData  [] -- 数组形式

    // 表格标签
    // tableLabel [{prop:xxx,label:xxxx}] -- 数组包含对象
        prop  逻辑操作 标签名
        label 中文显示 标签名

    //table 表格配置对象
    // tableConfig
        是否加载动画
        isShowLoading: true,
        改造状态列显示效果 el-switch
        alterState: 'state',

        当前页
        page: 1,
        总条目数
        total: 30,
        加载动画
        loading: false,

  -->

  <div class="common-table">
    <!-- 表格 -->
    <div v-if="tableConfig.isShowTopBtn">
      <el-checkbox v-model="allChecked"
                   style="margin:0px 18px"
                   @change="toggleAllSelection()">当页全选</el-checkbox>
      <slot name="topBtn"></slot>
    </div>
    <el-table :data="tableData"
              v-model="tableData"
              height="90%"
              style="width: 100%"
              v-loading="tableConfig.loading"
              :row-key="tableConfig.rowKey || 'id'"
              ref="elTable"
              default-expand-all
              :tree-props="{ children: 'children', hasChildren: true }">
      >
      <el-table-column align="center"
                       v-if="tableConfig.selection"
                       type="selection"
                       width="50">
      </el-table-column>

      <!-- <el-table-column align="center"
                       label="序号"
                       width="105">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column> -->
      <!-- 表格循环 -->
      <template v-for="item in tableLabel">
        <!-- id -->
        <el-table-column align="center"
                         v-if="item.type == 'id'"
                         :width="item.width"
                         show-overflow-tooltip
                         :key="item.id"
                         :label="item.label"
                         :prop="item.prop"
                         fixed
                         :sortable="item.prop == 'id' ? true : false"></el-table-column>
        <!-- 名称 -->
        <el-table-column align="center"
                         v-if="!item.type"
                         :width="item.width"
                         show-overflow-tooltip
                         :key="item.id"
                         :label="item.label"
                         :prop="item.prop">
        </el-table-column>

        <!-- 封面展示 -->
        <el-table-column align="center"
                         v-if="item.type == 'image'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            <img class="image"
                 v-if="scope.row[item.prop]"
                 :src="scope.row[item.prop]">
            <span v-else-if="!scope.row[item.prop]"
                  style="">加载失败
            </span>
          </template>
        </el-table-column>

        <!-- logo展示 -->
        <el-table-column align="center"
                         v-if="item.type == 'logo'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            <img class="logo"
                 v-if="scope.row[item.prop]"
                 :src="scope.row[item.prop]">
            <span v-else-if="!scope.row[item.prop]"
                  style="">
              <div><img src="../assets/img/default.png"
                     alt=""
                     width="50%"></div>
            </span>
          </template>
        </el-table-column>

        <!-- switch 开关 -->
        <el-table-column align="center"
                         v-if="item.type == 'state'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status "
                       :active-value="1"
                       :inactive-value="0"
                       active-color="#13ce66"
                       inactive-color="#DCDFE6"
                       @change="changeSwitch(scope.row)"></el-switch>
            <!-- <span v-if="scope.row.state == 1">正常</span>
            <span v-if="scope.row.state == 0">禁用</span> -->
          </template>
        </el-table-column>

        <!-- switch1/3 开关 3禁用 1启用 -->
        <el-table-column align="center"
                         v-if="item.type == 'state1/3'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status "
                       :active-value="1"
                       :inactive-value="3"
                       active-color="#13ce66"
                       inactive-color="#DCDFE6"
                       @change="changeSwitch(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <!-- switch自定义状态码 -->
        <el-table-column align="center"
                         v-if="item.type == 'customSwitch'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="180">
          <template slot-scope="scope">
            <!-- <el-switch v-model="scope.row.state "
                       :active-value="item.statusCode1"
                       :inactive-value="item.statusCode2"
                       active-color="#13ce66"
                       inactive-color="#DCDFE6"
                       @change="changeSwitch(scope.row)"></el-switch> -->
            <div style="color:#0486FE;cursor: pointer;font-size:12px"
                 v-if="scope.row.status == 0"
                 @click="changeSwitch(scope.row)">上架</div>
            <div style="color:#0486FE;cursor: pointer;font-size:12px"
                 v-if="scope.row.status == 2"
                 @click="changeSwitch(scope.row)">下架</div>
            <div style="color:black;"
                 v-if="scope.row.status == 1">未审核</div>
            <div style="color:black;"
                 v-if="scope.row.status == -1">审核未通过</div>
          </template>
        </el-table-column>
        <!-- 名字是status -->
        <el-table-column align="center"
                         v-if="item.type == 'stateS'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status "
                       :active-value="1"
                       :inactive-value="0"
                       active-color="#13ce66"
                       inactive-color="#DCDFE6"
                       @change="changeSwitch(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <!-- 不能触碰的状态 -->
        <el-table-column align="center"
                         v-if="item.type == 'stateNotouch'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">正常</span>
            <span v-if="scope.row.state == 0">禁用</span>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         v-if="item.type == 'stateSW'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">启用</span>
            <span v-if="scope.row.status == 0">禁用</span>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         v-if="item.type == 'yesOrNo'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.is_hot == 1">是</span>
            <span v-if="scope.row.is_hot == 0">否</span>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         v-if="item.type == 'hold'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.hold == 1">是</span>
            <span v-if="scope.row.hold == 0">否</span>
          </template>
        </el-table-column>

        <!-- 状态  
        //status		int	状态 3、下架 2、驳回 1、正常 0、申请-->
        <el-table-column align="center"
                         v-if="item.type == 'State'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">

            {{ scope.row.status == 0 ? "申请" : 
            (scope.row.status == 1 ? "正常" :
             scope.row.status == 2 ? "驳回" : "下架")}}
          </template>
        </el-table-column>

        <!-- 类型  
        //status		int	类型 3、转出 2、退回 1、提现 0、分润-->
        <el-table-column align="center"
                         v-if="item.type == 'AgentType'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">

            {{ scope.row.type == 0 ? "分润" : (scope.row.type == 1 ? "提现" : scope.row.type == 2 ? "退回" : "转出")}}
          </template>
        </el-table-column>

        <!-- 商品类型  
        //status		int	类型  -1、不通过 1，通过 0、申请-->
        <el-table-column align="center"
                         v-if="item.type == 'shopState'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">

          <template slot-scope="scope">
            {{  (scope.row.status == 1 ? "未审核" : "审核未通过")}}
          </template>

        </el-table-column>

        <!-- 收入/支出  
        //status		int	类型 1、收入 0、支出-->
        <el-table-column align="center"
                         v-if="item.type == 'in_out'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">
            {{ scope.row.in_out == 0 ? "支出" : "收入"}}
          </template>
        </el-table-column>

        <!-- 住宿订单状态  
        //status		int	状态 0.创建；1.支付; 2.确认; 3.入住; 4.评价; 5.退款; 6.已退款; 7.已取消 -->
        <el-table-column align="center"
                         v-if="item.type == 'stateOrder'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">

            {{ scope.row.order_status == 0 ? "创建" : 
            (scope.row.order_status == 1 ? "支付" : 
            (scope.row.order_status == 2) ? "确认" : 
            (scope.row.order_status == 3) ? "入住":
            (scope.row.order_status == 4) ? "评价":
            (scope.row.order_status == 5) ? "退款":
            (scope.row.order_status == 6) ? "已退款":
            "已取消")}}
          </template>
        </el-table-column>

        <!-- 住宿订单支付方式  
        //status		int	状态 0.余额;1.抵用金; 2.积分; 3.微信; 4.支付宝; 5.银联; -->
        <el-table-column align="center"
                         v-if="item.type == 'stateOrderpay'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         width="120">
          <template slot-scope="scope">

            {{ scope.row.pay_type == 0 ? "余额" : 
            (scope.row.pay_type == 1 ? "抵用金" : 
            (scope.row.pay_type == 2) ? "积分" : 
            (scope.row.pay_type == 3) ? "微信":
            (scope.row.pay_type == 4) ? "支付宝":
            "银联")}}
          </template>
        </el-table-column>

        <!-- radio 单选框  -->
        <el-table-column align="center"
                         v-if="item.type == 'radio'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         :width="item.width || 30">
          <template slot-scope="scope">
            <el-radio v-model="scope.row.prop"
                      :label="1">{{}}</el-radio>
            <el-radio v-model="scope.row.prop"
                      :label="2">{{}}</el-radio>
          </template>
        </el-table-column>

        <!-- 下拉框 -->
        <el-table-column align="center"
                         v-if="item.type == 'select'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         :width="item.width || 30">
          <template slot-scope="scope">
            <el-select v-model="scope.row[item.prop]"
                       size="mini"
                       :placeholder="'请选择'+item.label">
              <el-option v-for="selectItem in item.options"
                         :key="selectItem.value"
                         :label="selectItem.label"
                         :value="selectItem.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <!-- 输入框 -->
        <el-table-column align="center"
                         v-if="item.type == 'input'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label"
                         :width="item.width || 120">
          <template slot-scope="scope">
            <el-input v-model="scope.row[item.prop]"
                      :placeholder="'请输入' + item.label"
                      size="mini"
                      @blur="blurInput(scope.row[item.prop])"></el-input>
          </template>
        </el-table-column>

        <!-- 日期选择器框 -->
        <el-table-column align="center"
                         v-if="item.type == 'datePicker'"
                         show-overflow-tooltip
                         fixed="left"
                         :key="item.prop"
                         :label="item.label"
                         :width="item.width || 180"
                         :sortable="true">
          <template slot-scope="scope">
            <el-date-picker v-model="scope.row[item.prop]"
                            type="date"
                            format="yyyy-MM-dd"
                            size="mini"
                            placeholder="选择日期">
            </el-date-picker>
            <span class="date-tip">{{dateFormatWeek(scope.row[item.prop])}}</span>
          </template>
        </el-table-column>

        <!-- 显示日期  时间戳 to yyyy-mm-dd -->
        <el-table-column align="center"
                         v-if="item.type == 'dateTime'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label">
          <template slot-scope="scope">
            {{dateFormatDeal(scope.row.last_date)}}
          </template>
        </el-table-column>
        <!-- 性别 判断 -->
        <el-table-column align="center"
                         v-if="item.type == 'sex'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label">
          <template slot-scope="scope">
            {{ scope.row.sex == 1 ? "男" : "女" }}
          </template>
        </el-table-column>

        <!-- 上架情况 判断 -->
        <el-table-column align="center"
                         v-if="item.type == 'statue'"
                         show-overflow-tooltip
                         :key="item.prop"
                         :label="item.label">
          <template slot-scope="scope">
            {{ scope.row.state == 1 ? "上架中" : "下架" }}
          </template>
        </el-table-column>

        <!-- 商品订单的表格 -->
        <el-table-column align="center"
                         v-if="item.type == 'shop'"
                         :width="item.width"
                         :key="item.id"
                         :label="item.label"
                         :prop="item.prop">

          <template slot-scope="scope">
            <div class="shopBox">
              <span class="boxHeader">
                <ul>
                  <li class="info">订单号: {{scope.row.no || scope.row.id}} </li>
                  <li class="info">下单时间: {{scope.row.create_time}}</li>
                  <li class="btn"
                      @click="CancellationOfOrder(scope.row)">取消订单</li>
                  <li class="btn"
                      v-if="scope.row.description"
                      @click="RefundOfOrder(scope.row)">退款</li>

                </ul>
              </span>
              <!-- {{scope.row.shop_order_detail[0].shop_order_goods}} -->
              <div v-for="itemShop in scope.row.shop_order_detail"
                   :key="itemShop.id">
                <ul class="supplier">
                  <div class="supInfo">供应商:{{itemShop.supplier_id}}</div>

                  <div class="btn"
                       @click="clickExpressData(scope.row,itemShop)">
                    设置快递信息
                  </div>
                  <div class="btn"
                       @click="clickAfterSale(scope.row,itemShop)">
                    申请售后
                  </div>
                  <div class="btn"
                       @click="clickDetailData(scope.row,itemShop)">查看详情</div>
                  <div class="btn"
                       @click="clickInfoData(scope.row)">发货</div>
                </ul>
                <div v-for="itemShop2 in itemShop.shop_order_goods"
                     :key="itemShop2.id">
                  <div class="orderBox">
                    <ul>
                      <li class="first">
                        <img :src='itemShop2.goods_image'
                             alt="">
                        <div class="goods_name">
                          {{itemShop2.goods_name}}-{{itemShop2.goods_sku_name}}
                        </div>
                        <div class="goods_sku_name">
                          {{itemShop2.goods_sku_name}}
                        </div>
                        <div class="price">{{itemShop2.sales_price}}</div>
                        <div class="num">{{itemShop2.quantity}}件</div>

                      </li>
                      <li v-if="itemShop2.status==-1">不通过</li>
                      <li v-if="itemShop2.status==0">审核中</li>
                      <li v-if="itemShop2.status==1">审核已通过</li>
                      <li v-if="itemShop2.status==2">售后处理中 </li>
                      <li v-if="itemShop2.status==3">已发货</li>
                      <li v-if="itemShop2.status==4">已完成</li>
                      <li>
                        <div>{{scope.row.real_name}}</div>
                        <div>{{scope.row.mobile}}</div>
                      </li>
                      <li>{{itemShop.express_com_id}}</li>
                      <li>￥{{itemShop2.total_price}}</li>

                      <li v-if="scope.row.shop_order_detail[0].status == 0">待付款</li>
                      <li v-if="scope.row.shop_order_detail[0].status == 1">已支付 </li>
                      <li v-if="scope.row.shop_order_detail[0].status == 2">已完成</li>
                      <li v-if="scope.row.shop_order_detail[0].status == 3">订单已取消</li>
                      <li v-if="scope.row.shop_order_detail[0].status == 4">交易失败</li>
                      <li v-if="scope.row.shop_order_detail[0].status == 5">待发货</li>
                      <li v-if="scope.row.shop_order_detail[0].status == 6">发货中</li>
                      <li v-if="scope.row.shop_order_detail[0].status == 7">待收货</li>
                      <!-- <el-table-column :formatRole="formatRole"></el-table-column> -->
                      <!-- <li :formatRole="formatRole">{{scope.row.shop_order_detail[0].status}}</li> -->
                      <li v-if="scope.row.buy_type==0">银行卡支付</li>
                      <li v-if="scope.row.buy_type==1">微信支付</li>
                      <li v-if="scope.row.buy_type== 2">支付宝支付</li>
                      <li v-if="scope.row.buy_type==3">余额支付</li>
                      <li>? </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

          </template>
        </el-table-column>

        <el-table-column :label="item.label"
                         fixed="right"
                         :key="item.label"
                         :width="item.width"
                         v-if="item.type == 'operation'"
                         style="text-align:center">
          <template slot-scope="scope">
            <div v-for="childItem in item.children"
                 :key="childItem.type">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          :content="childItem.content"
                          placement="top"
                          v-if="childItem.type === 'primary'">
                <el-button type="text"
                           size="mini"
                           @click="clickEditData(scope.row)">{{childItem.content}}</el-button>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          :content="childItem.content"
                          placement="top"
                          v-if="childItem.type === 'warning'">
                <el-button type="text"
                           size="mini"
                           @click="clickWarningData(scope.row)">{{childItem.content}}</el-button>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          :content="childItem.content"
                          placement="top"
                          v-if="childItem.type === 'info'">
                <el-button type="text"
                           size="mini"
                           @click="clickInfoData(scope.row)">{{childItem.content}}</el-button>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          :content="childItem.content"
                          placement="top"
                          v-if="childItem.type == 'danger'">
                <el-button type="text"
                           size="mini"
                           @click="clickDeleteData(scope.row)">{{childItem.content}}</el-button>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          :content="childItem.content"
                          placement="top"
                          v-if="childItem.type == 'addSub'">
                <el-button type="text"
                           size="mini"
                           @click="addSubcategory(scope.row)">{{childItem.content}}</el-button>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          :content="childItem.content"
                          placement="top"
                          v-if="childItem.type == 'remarks'">
                <el-button type="text"
                           size="mini"
                           @click="clickRemarks(scope.row)">{{childItem.content}}</el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>

      </template>
      <!-- 操作 -->
      <slot></slot>
    </el-table>

    <!-- 分页 -->
    <el-pagination v-if="tableConfig.isShowLoading"
                   :data="tableData"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="tableConfig.total"
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page.sync="tableConfig.page"></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    //表格内数据
    tableData: Array,
    tableLabel: Array,
    newTableData: Array,
    tableConfig: Object,
  },
  data () {
    return {
      allChecked: false,
      supply: '门票',
      // newTableData: this.tableData
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  updated () {
  },
  computed: {
  },
  watch: {
    tableData: function () {
      this.allChecked = false
    },

    // page: function () {
    //   console.log(this.page)
    // }
  },
  filters: {
    // switchValue:function(value){
    //  return value == '1'? true:false
    // }
  },
  methods: {
    //数据转换
    // formatRole: function (row) {
    //   return row.shop_order_detail[0].status == 0 ? "待付款" : row.shop_order_detail[0].status == 1 ? "已支付" : row.shop_order_detail[0].status == 2 ? "已取消" : row.shop_order_detail[0].status == 3 ? "交易失败" : row.shop_order_detail[0].status == 4 ? "待付款" : row.shop_order_detail[0].status == 5 ? "待发货" : row.shop_order_detail[0].status == 6 ? "发货中" : row.shop_order_detail[0].status == 7 ? "待收货" : "暂无";
    // },
    clickAfterSale () {
      console.log('点击了申请退货');
    },
    aaa (scope) {
      console.log(scope)
      if (scope.row.type[0][0] == 1) {
        this.supply = '酒馆'
      }
    },
    handleSizeChange (val) {
      console.log(val)
      this.$emit('getNumArticle', val)
    },
    handleCurrentChange () {
      console.log(this.tableConfig.page)
      this.$emit('getPage', this.tableConfig.page)
    },
    dateFormatWeek (date) {
      const week = new Date(date).getDay();
      let weekConfig = '';
      switch (week) {
        case 0:
          weekConfig = '周日'
          break;
        case 1:
          weekConfig = '周一'
          break;
        case 2:
          weekConfig = '周二'
          break;
        case 3:
          weekConfig = '周三'
          break;
        case 4:
          weekConfig = '周四'
          break;
        case 5:
          weekConfig = '周五'
          break;
        case 6:
          weekConfig = '周六'
          break;
        default:
          console.log('xx');
          break;
      }
      return weekConfig;
    },
    // clickEditUser (row) {
    //   this.$emit('clickEditUser', row)
    // }
    dateFormatDeal (time) {
      if (time == 0) {
        return '无'
      }
      const date = new Date(time * 1000);
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    },
    //开关按钮
    changeSwitch (row) {
      this.$emit('changeSwitch', row)
    },
    //编辑按钮
    clickEditData (row) {
      this.$emit('clickEditData', row)
    },
    //警告按钮
    clickWarningData (row) {
      this.$emit('clickWarningData', row)
    },
    //商品订单中查看详情按钮
    clickDetailData (row1, row2) {
      this.$emit('clickDetailData', row1, row2)
    },
    //申请售后
    clickAfterSale (row1, row2) {
      this.$emit('clickAfterSale', row1, row2)
    },
    //设置快递信息按钮
    clickExpressData (row1, row2) {
      this.$emit('clickExpressData', row1, row2)
    },
    //取消商城订单
    CancellationOfOrder (row1, row2) {
      this.$emit('CancellationOfOrder', row1, row2)
    },
    RefundOfOrder (row1, row2) {
      this.$emit('RefundOfOrder', row1, row2)
    },
    //提示信息按钮
    clickInfoData (row) {
      this.$emit('clickInfoData', row)
    },
    //删除按钮
    clickDeleteData (row) {
      this.$emit('clickDeleteData', row)
    },
    //备注按钮
    clickRemarks (row) {
      this.$emit('clickRemarks', row)
    },
    //添加子分类按钮
    addSubcategory (row) {
      this.$emit('addSubcategory', row)
    },
    //失去焦点 自动保存
    blurInput (val) {
      this.$emit('blurInput', val)
    },
    //table 当前页面全选
    toggleAllSelection () {
      if (this.allChecked) {
        this.$refs.elTable.toggleAllSelection()
      } else {
        this.$refs.elTable.clearSelection()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.shopBox {
  width: 100%;
  height: 100%;
  border: 1px solid #dcdfe6;
  ul {
    width: 100%;
    text-align: center;
    margin-left: -40px;
    li {
      list-style: none;
    }
  }
  .supplier {
    width: 100%;
    text-align: left;
    margin-top: -3px;
    .btn {
      // position: absolute;
      float: right;
      margin-top: -22px;
      margin-left: 7px;
      margin-right: 10px;
      color: #409eff;
      cursor: pointer;
    }
    .supInfo {
      margin-left: 20px;
    }
  }
  .boxHeader {
    display: inline-block;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #dcdfe6;
    background-color: #f8fbff;
    ul {
      .info {
        float: left;
        margin-left: 10px;
      }
      .btn {
        color: #409eff;
        float: right;
        margin-right: 14px;
        cursor: pointer;
      }
    }
  }

  .orderBox {
    ul {
      margin-top: -10px;
      li {
        float: left;
        width: 9.73%;
        height: 100px;
        border: 1px solid #dcdfe6;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-right: none;
      }
    }
    .first {
      width: 31%;
      flex-direction: row;
      text-align: left;
      justify-content: flex-start;
      position: relative;
      img {
        width: 72px;
        height: 72px;
        margin: 16px;
      }
      .goods_name {
        width: 200px;
        position: absolute;
        top: 9px;
        left: 95px;
      }
      .goods_sku_name {
        width: 180px;
        position: absolute;
        top: 65px;
        left: 95px;
      }
      .price {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      .num {
        position: absolute;
        top: 40px;
        right: 20px;
      }
    }
  }
}

.common-table {
  position: relative;
  height: 100%;

  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }
  /deep/ .el-tabs__content {
    height: 806px;
  }
  // 滚动条的滑块
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 3px;
  }

  .el-pagination {
    float: right;
    margin-right: 25px;
    margin-top: 10px;
  }
  .image {
    width: 60px;
    height: 40px;
  }
  .logo {
    width: 40px;
    height: 40px;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .date-tip {
    font-weight: bold;
    display: block;
    margin-left: 3px;
  }
  /deep/ .cell {
    text-align: center;
  }
}
</style>
